import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { fbLink, linkedinLink } from '../../assets/links'
import IconFacebook from '../icons/facebook'
import IconLinkedin from '../icons/linkedin'

interface Props {
  mobileOpen: boolean
}
export default function Footer({ mobileOpen }: Props): JSX.Element {
  const { t } = useTranslation()

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        top: 'auto',
        bottom: 0,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        height: '60px',
        display: mobileOpen ? 'none' : 'flex',
        backgroundColor: (theme) => theme.palette.grayBackground,
        '@media (max-width:400px)': { height: '90px' },
      }}
    >
      <Toolbar
        sx={{
          height: '60px',
          justifyContent: 'space-between',
          '@media (max-width:400px)': {
            display: mobileOpen ? 'none' : 'block',
            textAlign: 'center',
            paddingTop: '16px',
          },
        }}
      >
        <Box sx={{ ml: 1, '@media (max-width:400px)': { margin: '0' } }}>
          <Typography>{t('footer.rights')}</Typography>
        </Box>
        <Box sx={{ mr: 1, '@media (max-width:400px)': { margin: '0' } }}>
          <IconButton color="inherit" aria-label="open drawer" target="_blank" href={fbLink}>
            <IconFacebook />
          </IconButton>

          <IconButton color="inherit" aria-label="open drawer" target="_blank" href={linkedinLink}>
            <IconLinkedin />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
