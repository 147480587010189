import React from 'react'
import { Props } from '../props.interface'

export default function IconInvestement({ fill, width, height }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon/placements</title>
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2--1--Profil" transform="translate(-30.000000, -255.000000)">
          <g id="menu" transform="translate(29.000000, 177.000000)">
            <g id="Group" transform="translate(0.000000, 5.000000)">
              <g id="icon/placements" transform="translate(0.000000, 72.000000)">
                <rect id="ViewBox" x="0" y="0" width="24" height="24" />
                <path
                  d="M4.47368421,16.6315789 L1,20.0357895 L1,10.2631579 L4.47368421,10.2631579 M10.2631579,14.5010526 L8.44526316,12.9494737 L6.78947368,14.4778947 L6.78947368,5.63157895 L10.2631579,5.63157895 M16.0526316,12.5789474 L12.5789474,16.0526316 L12.5789474,1 L16.0526316,1 M19.3063158,12.3589474 L17.2105263,10.2631579 L23,10.2631579 L23,16.0526316 L20.9273684,13.98 L12.5789474,22.2589474 L8.56105263,18.7621053 L4.18421053,23 L1,23 L8.49157895,15.6589474 L12.5789474,19.1094737"
                  id="Shape"
                  fill={fill}
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
IconInvestement.defaultProps = {
  fill: 'none',
  stroke: 'none',
  width: '22px',
  height: '22px',
}
