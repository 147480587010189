import React from 'react'
import IconAccounting from '../components/icons/accounting'
import IconDashboard from '../components/icons/dashboard'
import IconDocuments from '../components/icons/documents'
import IconFinancial from '../components/icons/financial'
import IconInsurance from '../components/icons/insurance'
import IconInvestement from '../components/icons/investement'
import IconMortgage from '../components/icons/mortgage'
import IconProfil from '../components/icons/profil'
import { Props } from '../components/icons/props.interface'

const AccountingScreen = React.lazy(() => import('../screens/accounting'))
const DocumentsScreen = React.lazy(() => import('../screens/documents'))
const FinancialPlanScreen = React.lazy(() => import('../screens/financialPlan'))
const InsuranceScreen = React.lazy(() => import('../screens/insurance'))
const InvestmentsScreen = React.lazy(() => import('../screens/investments'))
const MortgageScreen = React.lazy(() => import('../screens/mortgage'))
const NotFound = React.lazy(() => import('../screens/notFound'))
const Permission = React.lazy(() => import('../screens/permission'))
const ProfilScreen = React.lazy(() => import('../screens/profil'))

export interface IRoutes {
  path: string
  sidebarName?: string
  component: React.FC
  protected: boolean
  icon: ({ fill, stroke, width, height }: Props) => JSX.Element
  fill?: boolean
  stroke?: boolean
}

const RoutesList: IRoutes[] = [
  {
    path: '/investement',
    sidebarName: 'investement',
    component: InvestmentsScreen,
    protected: true,
    icon: IconInvestement,
    fill: true,
    stroke: false,
  },
  {
    path: '/insurance',
    sidebarName: 'insurance',
    component: InsuranceScreen,
    protected: true,
    icon: IconInsurance,
    fill: true,
    stroke: false,
  },
  {
    path: '/mortgage',
    sidebarName: 'mortgage',
    component: MortgageScreen,
    protected: true,
    icon: IconMortgage,
    fill: true,
    stroke: false,
  },
  {
    path: '/accounting',
    sidebarName: 'accounting',
    component: AccountingScreen,
    protected: true,
    icon: IconAccounting,
    fill: true,
    stroke: false,
  },
  {
    path: '/financial-plan',
    sidebarName: 'financial_plan',
    component: FinancialPlanScreen,
    protected: true,
    icon: IconFinancial,
    fill: false,
    stroke: true,
  },
  {
    path: '/documents',
    sidebarName: 'documents',
    component: DocumentsScreen,
    protected: true,
    icon: IconDocuments,
    fill: false,
    stroke: true,
  },

  {
    path: '/profil/*',
    sidebarName: 'profil',
    component: ProfilScreen,
    protected: true,
    icon: IconProfil,
    fill: true,
    stroke: false,
  },
  {
    path: '/*',
    component: NotFound,
    protected: true,
    icon: IconDashboard,
    fill: true,
    stroke: false,
  },
  {
    path: '/permission',
    component: Permission,
    protected: false,
    icon: IconDashboard,
  },
]

export default RoutesList
