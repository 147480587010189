/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Link,
  Modal,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import IconClose from '../../icons/close'
import ButtonRounded from '../buttonRounded'

interface Props {
  open: boolean
  handleClose: () => void | undefined
  title: string
  children: React.ReactElement
  handleAction?: () => void
  showActionSection?: boolean
}
export default function BasicModal({
  open,
  handleClose,
  title,
  children,
  handleAction,
  showActionSection,
}: Props) {
  const { palette } = useTheme()
  const { t } = useTranslation()
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backgroundColor: '#86BC6490',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          position: 'absolute' as const,
          // top: '50%',
          // left: '50%',
          // transform: 'translate(-50%, -50%)',
          width: { sm: 'auto', xs: '99%' },
          maxHeight: '95%',
          bgcolor: 'background.paper',
          borderBottomColor: palette.primary.main,
          boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
          borderRadius: '5px 5px 5px 5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // justifyContent: 'center',
          borderBottomStyle: 'solid',
          borderBottomWidth: '5px',
          paddingBottom: 1,
          overflow: 'auto',
          // backdropFilter: 'blur(5px)',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ marginTop: 3, typography: { sm: 'h1', xs: 'h6' } }}>{title}</Box>

          <IconButton sx={{ position: 'absolute', top: '5px', right: '5px' }} onClick={handleClose}>
            <IconClose />
          </IconButton>
        </Box>

        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}
        </CardContent>
        {showActionSection && (
          <CardActions
            sx={{
              p: 2,
              width: '90%',
              display: 'flex',
              flexDirection: { sm: 'row', xs: 'column-reverse' },
              justifyContent: { sm: 'space-between', xs: 'center' },
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link
              component="button"
              variant="body2"
              onClick={() => handleClose()}
              sx={{
                display: 'flex',
                fontSize: '18px',
                justifyContent: 'center',
                mt: { sm: 0, xs: 1 },
              }}
            >
              {t('common.cancel')}
            </Link>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <ButtonRounded
                handleAction={handleAction}
                display="flex"
                title={t('common.submit')}
                style={{
                  margin: 0,
                }}
                borderColor={palette.primary.main}
              />
            </Box>
            <Box sx={{ display: 'flex' }} />
          </CardActions>
        )}
      </Card>
    </Modal>
  )
}
BasicModal.defaultProps = {
  handleAction: () => true,
  showActionSection: true,
}
