import React from 'react'
import { Props } from '../props.interface'

export default function IconCalendar({ fill, width, height }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon/calendar 2</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="bandeau-cta-v2" transform="translate(-708.000000, -37.000000)">
          <g id="mdi:calendar-clock" transform="translate(703.000000, 36.000000)">
            <rect id="ViewBox" x="0" y="0" width="40" height="40" />
            <path
              d="M25,21.6666667 L27.5,21.6666667 L27.5,26.3666667 L31.5666667,28.7166667 L30.3166667,30.8833333 L25,27.8166667 L25,21.6666667 M31.6666667,13.3333333 L8.33333333,13.3333333 L8.33333333,31.6666667 L16.1166667,31.6666667 C15.4,30.15 15,28.45 15,26.6666667 C15,20.2233446 20.2233446,15 26.6666667,15 C28.45,15 30.15,15.4 31.6666667,16.1166667 L31.6666667,13.3333333 M8.33333333,35 C6.49238417,35 5,33.5076158 5,31.6666667 L5,8.33333333 C5,6.48333333 6.48333333,5 8.33333333,5 L10,5 L10,1.66666667 L13.3333333,1.66666667 L13.3333333,5 L26.6666667,5 L26.6666667,1.66666667 L30,1.66666667 L30,5 L31.6666667,5 C33.5076158,5 35,6.49238417 35,8.33333333 L35,18.5 C37.0666667,20.6 38.3333333,23.4833333 38.3333333,26.6666667 C38.3333333,33.1099887 33.1099887,38.3333333 26.6666667,38.3333333 C23.4833333,38.3333333 20.6,37.0666667 18.5,35 L8.33333333,35 M26.6666667,18.5833333 C22.2023649,18.5833333 18.5833333,22.2023649 18.5833333,26.6666667 C18.5833333,31.1333333 22.2,34.75 26.6666667,34.75 C28.8105,34.75 30.8665274,33.8983655 32.3824465,32.3824465 C33.8983655,30.8665274 34.75,28.8105 34.75,26.6666667 C34.75,22.2 31.1333333,18.5833333 26.6666667,18.5833333 Z"
              id="Shape"
              fill={fill}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
IconCalendar.defaultProps = {
  fill: 'none',
  stroke: 'none',
  width: '34px',
  height: '34px',
}
