/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMessage, Stomp } from '@stomp/stompjs'
import { useCallback, useEffect, useState } from 'react'
import SockJS from 'sockjs-client'
import onHandleNotification from 'src/redux/actions/notification.action'
import { useAppDispatch } from 'src/redux/store'

const useWebSocket = (topic: string): [boolean] => {
  const [newNotification, setNewNotification] = useState(false)
  const dispatch = useAppDispatch()

  const connect = useCallback(() => {
    const stompClient = Stomp.over(new SockJS(`${process.env.REACT_APP_WEBSOCKET_URL}`))
    stompClient.debug = (msg: string) => {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(msg)
      }
    }

    stompClient.connect({}, () => {
      stompClient.subscribe(topic, (message: IMessage) => {
        const messages = JSON.parse(message.body)
        const userJson = JSON.parse(localStorage.getItem('user') || '{}')

        if (messages.filter((e: any) => e === userJson.id).length > 0) {
          setNewNotification(!newNotification)
          dispatch(onHandleNotification(false))
        }
      })
    })
  }, [dispatch, newNotification, topic])

  useEffect(() => {
    connect()
  }, [connect])
  return [newNotification]
}
export default useWebSocket
