/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWebSocket from 'src/hooks/useWebSocket'
import {
  Configuration,
  NotificationsApi,
  NotificationSearchResult,
  NotificationSearchResultContent,
} from '../../api'
import ApiStateHandler from '../../components/apiStateHandler'
import CardCTA from '../../components/dashboardScreen/cardCTA'
import CardNotification from '../../components/dashboardScreen/cardNotification'
import CardsNavigator from '../../components/dashboardScreen/cardsNavigator'
import useApiCallOnMount from '../../hooks/useApiCallOnMount'
import handleAccessToken from '../../services/config'

export default function DashboardScreen() {
  const { t } = useTranslation()
  const [notifications, setNotifications] = useState<Array<NotificationSearchResultContent>>([])
  const [newNotification] = useWebSocket('/topic/notification')

  const api = React.useMemo(() => {
    const notification = new NotificationsApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: handleAccessToken.getAccessTokenSilently(),
      }),
    )
    return notification.searchMyNotifications('fr', {
      search: {},
      sortBy: 'createdDate',
      sortDir: 'DESC',
      pageIndex: 0,
      pageSize: 3,
    })
  }, [])
  const [loading, data, error, startLoading, stopLoading] =
    useApiCallOnMount<NotificationSearchResult>(api)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getNotifications = useCallback(async () => {
    const notification = new NotificationsApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: handleAccessToken.getAccessTokenSilently(),
      }),
    )
    const result = await notification.searchMyNotifications('fr', {
      search: {},
      sortBy: 'createdDate',
      sortDir: 'DESC',
      pageIndex: 0,
      pageSize: 3,
    })
    setNotifications(result.data.content)
  }, [])

  useEffect(() => {
    getNotifications()
  }, [getNotifications, newNotification])

  React.useEffect(() => {
    if (data) {
      startLoading()
      setNotifications(data.content)
      stopLoading()
    } else {
      stopLoading()
    }
    return () => {
      stopLoading()
    }
  }, [data, stopLoading, startLoading])

  return (
    <ApiStateHandler loading={loading} error={error}>
      <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h1" sx={{ '@media (max-width:400px)': { fontSize: '2rem' } }}>
          {t('dashboard.title')}
        </Typography>
        <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItem: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 3,
            }}
          >
            <CardNotification notifications={notifications} />
            <CardsNavigator />
          </Box>

          <CardCTA />
        </Box>
      </Box>
    </ApiStateHandler>
  )
}
