/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@mui/material'
import React from 'react'

interface IProps {
  handleAction?: () => void
  title: string
  style?: any
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
}

export default function ButtonRounded({ handleAction, title, style, type, disabled }: IProps) {
  return (
    <Button
      color="inherit"
      onClick={handleAction}
      disabled={disabled}
      type={type}
      sx={{
        marginLeft: 2,
        fontSize: 18,
        letterSpacing: 0.8,
        textAlign: 'center',
        paddingInline: 2,
        lineHeight: '26px',
        ...style,
      }}
    >
      {title}
    </Button>
  )
}
ButtonRounded.defaultProps = {
  style: {},
  type: 'button',
  handleAction: () => {},
  disabled: false,
}
