import { SET_NOTIFICATION } from '../actionTypes'
import { INotification } from '../types'

const init: INotification = {
  notificationReaded: false,
}
export default function notificationReducer(state = init, { type, payload }: any = {}) {
  switch (type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        notificationReaded: payload,
      }

    default:
      return state
  }
}
