import React from 'react'

export default function IconLinkedin(): JSX.Element {
  return (
    <svg
      width="31px"
      height="30px"
      viewBox="0 0 31 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon/linkedin</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="footer" transform="translate(-1360.000000, -16.000000)">
          <g id="entypo-social:linkedin-with-circle" transform="translate(1360.480000, 16.000000)">
            <rect id="ViewBox" x="0" y="0" width="30" height="30" />
            <path
              d="M15,0 C6.715625,0 0,6.715625 0,15 C0,23.284375 6.715625,30 15,30 C23.284375,30 30,23.284375 30,15 C30,6.715625 23.284375,0 15,0 Z M10.8691702,22.0234375 L7.48212743,22.0234375 L7.48212743,11.1235713 L10.8691702,11.1235713 L10.8691702,22.0234375 Z M9.15474116,9.78548032 C8.08496529,9.78548032 7.39326982,9.02757722 7.39326982,8.0902166 C7.39326982,7.13369062 8.10587296,6.3984375 9.19829881,6.3984375 C10.2907247,6.3984375 10.9597702,7.13369062 10.9806778,8.0902166 C10.9806778,9.02757722 10.2907247,9.78548032 9.15474116,9.78548032 Z M23.2395427,22.0234375 L19.8524999,22.0234375 L19.8524999,15.9828627 C19.8524999,14.5768218 19.3611696,13.6220381 18.1363285,13.6220381 C17.2007102,13.6220381 16.6449146,14.2684336 16.3992494,14.8904368 C16.3086495,15.1117097 16.2859995,15.4253248 16.2859995,15.7371975 L16.2859995,22.0216952 L12.8972144,22.0216952 L12.8972144,14.5994717 C12.8972144,13.2387308 12.8536568,12.101005 12.8083568,11.121829 L15.7511116,11.121829 L15.9061768,12.6358929 L15.9741268,12.6358929 C16.4201571,11.9250321 17.5125829,10.8761639 19.3402619,10.8761639 C21.5686712,10.8761639 23.2395427,12.3693201 23.2395427,15.5786477 L23.2395427,22.0234375 L23.2395427,22.0234375 Z"
              id="Shape"
              fill="#B5B4AB"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
IconLinkedin.defaultProps = {
  fill: 'none',
  stroke: 'none',
  width: '31px',
  height: '30px',
}
