import React from 'react'
import { Props } from '../props.interface'

export default function IconClose({ stroke, width, height }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>close </title>
      <g
        id="Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="8-1--Hypothèque-edit"
          transform="translate(-1120.000000, -112.000000)"
          stroke={stroke}
          strokeWidth="3"
        >
          <g id="popup" transform="translate(281.000000, 83.000000)">
            <path
              d="M840.707107,30.7071068 L851.313708,41.3137085 M851.313708,30.7071068 L840.707107,41.3137085"
              id="close-"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
IconClose.defaultProps = {
  fill: 'none',
  stroke: '#86BC64',
  width: '14px',
  height: '14px',
}
