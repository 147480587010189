import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import Loader from '../loader'

interface Props {
  loading?: boolean
  error?: boolean
  children: React.ReactElement
}
export default function ApiStateHandler({ loading, error, children }: Props) {
  const allowed = useSelector((state: RootState) => state.permissionReducer.allowed)
  const { t } = useTranslation()
  if (error) {
    return <Typography>{t('error_page')}</Typography>
  }
  return (
    <>
      <Loader loading={loading || false} /> {allowed && children}
    </>
  )
}

ApiStateHandler.defaultProps = {
  loading: false,
  error: false,
}
