/* -------------------------------------------------------------------------- */
/*                                   IMPORTS                                  */
/* -------------------------------------------------------------------------- */
import { useAuth0, User, withAuthenticationRequired } from '@auth0/auth0-react'
import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RoleType } from '../api'
import Loader from '../components/loader'
import { RootState } from '../redux/store'

/* -------------------------------------------------------------------------- */
/*                                  INTERFACE                                 */
/* -------------------------------------------------------------------------- */
interface ProtectedRouteProps {
  component: ComponentType
}

export default function ProtectedRoute({ component }: ProtectedRouteProps): JSX.Element {
  const { isLoading } = useAuth0()
  const loading = useSelector((state: RootState) => state.loadingReducer.loading)

  const navigate = useNavigate()

  const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE
  const rolesClaimKey = `${audience}/roles`
  const acceptedRoles = [RoleType.Client.toUpperCase()]
  const loader = () => <Loader loading={loading && isLoading} />
  const checkRoles = (user?: User) => {
    if (user && user[rolesClaimKey] && Array.isArray(user[rolesClaimKey])) {
      const usersRoles = user[rolesClaimKey] as string[]
      const hasRoles = acceptedRoles.some((acceptedRole) => usersRoles.includes(acceptedRole))
      if (!hasRoles) {
        // TODO forward to a page saying the user doesn't have the good role
        /*
        logout({
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          returnTo: window.location.origin,
        })
        */
        // dispatch(onHandlePermission(false))
        navigate('/permission')

        return false
      }
      // else {
      // dispatch(onHandlePermission(true))
      // }
    }
    return true
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: loader,
    claimCheck: checkRoles,
  })
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  return <Component />
}
