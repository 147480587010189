import { Box, CardContent, Divider, Link, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function JoinUs() {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Divider />

      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: 10,
        }}
      >
        <Typography align="center" variant="h4">
          {t('join_us.address_one')}:&nbsp;
          <Link href={`tel:${t('join_us.number_one')}`}>{t('join_us.number_one')}</Link>
        </Typography>
        <Typography align="center" variant="h4">
          {t('join_us.address_two')}:&nbsp;
          <Link href={`tel:${t('join_us.number_two')}`}>{t('join_us.number_two')}</Link>
        </Typography>
        <Typography align="center" variant="h4">
          {t('join_us.address_three')}:&nbsp;
          <Link href={`tel:${t('join_us.number_three')}`}>{t('join_us.number_three')}</Link>
        </Typography>
      </CardContent>
    </Box>
  )
}
