import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
import './helpers/i18n'
import theme from './helpers/theme'
import { store } from './redux/store'

Sentry.init({
  dsn: 'https://3e511c3c19a34063aa0293f155d07902@o527182.ingest.sentry.io/4505244978708480',
  release: `growtheco-portail@${process.env.BUILD_ID}`,
  environment: process.env.NODE_ENV,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

ReactDOM.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.querySelector('#root'),
)
