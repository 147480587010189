import { useDispatch } from 'react-redux'
import { Action, applyMiddleware, combineReducers, createStore } from 'redux'
import thunk, { ThunkDispatch } from 'redux-thunk'
import loadingReducer from './reducers/loading.reduser'
import notificationReducer from './reducers/notification.reducer'
import permissionReducer from './reducers/permission.reducer'
import selectedTabReducer from './reducers/selectedTab.reducer'
import snackbarReducer from './reducers/snackbar.reducer'
import userProfileReducer from './reducers/userProfile.reducer'

const reducer = combineReducers({
  userProfileReducer,
  loadingReducer,
  selectedTabReducer,
  permissionReducer,
  snackbarReducer,
  notificationReducer,
})

export const store = createStore(reducer, applyMiddleware(thunk))
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>
export const useAppDispatch = () => useDispatch<ThunkAppDispatch>()
