/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import setSnackbar from '../redux/actions/snackbar.action'
import { useAppDispatch } from '../redux/store'

const apiStatus = {
  loading: 'loading',
  complete: 'complete',
  errored: 'errored',
}

const useApiCallOnMount = <T>(
  service: Promise<AxiosResponse<T, any>>,
): [
  boolean,
  T | undefined,
  boolean,
  () => void,
  () => void,
  React.Dispatch<React.SetStateAction<boolean>>,
  boolean,
] => {
  const [status, setStatus] = useState<string>(apiStatus.loading)
  const [data, setData] = useState<T | undefined>(undefined)
  const [relaunch, setRelaunch] = useState(false)
  const dispatch = useAppDispatch()
  const startloading = useCallback(() => {
    setStatus(apiStatus.loading)
  }, [])
  const stopLoading = useCallback(() => {
    setStatus(apiStatus.complete)
  }, [])

  useEffect(() => {
    service
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        dispatch(setSnackbar(true, 'error', (err as Error).message))
        setStatus(apiStatus.errored)
      })
  }, [service, dispatch, relaunch])
  return [
    status === apiStatus.loading,
    data,
    status === apiStatus.errored,
    startloading,
    stopLoading,
    setRelaunch,
    relaunch,
  ]
}
export default useApiCallOnMount
