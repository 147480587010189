import NotificationsSharpIcon from '@mui/icons-material/NotificationsSharp'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function CardNavigatorTitle() {
  const { t } = useTranslation()
  const { palette } = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <IconButton
        sx={{
          marginRight: 1,
        }}
      >
        <NotificationsSharpIcon color="primary" />
      </IconButton>
      <Typography
        sx={{
          color: palette.text.primary,
          fontFamily: 'Alegreya Sans',
          fontSize: '24px',
          letterSpacing: '0.75px',
          lineHeight: '29px',
        }}
      >
        {t('notification.title')}
      </Typography>
    </Box>
  )
}
