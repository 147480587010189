import React from 'react'
import { Props } from '../props.interface'

export default function IconFinancial({ stroke, width, height }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon/plan financier</title>
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2--1--Profil" transform="translate(-29.000000, -546.000000)">
          <g id="menu" transform="translate(29.000000, 177.000000)">
            <g id="Group" transform="translate(0.000000, 5.000000)">
              <g id="icon/plan-financier" transform="translate(0.000000, 364.000000)">
                <rect id="ViewBox" x="0" y="0" width="24" height="24" />
                <g id="Group">
                  <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                  <path
                    d="M5.98717949,3.15384615 L3.78205128,3.15384615 C2.5641926,3.15384615 1.57692308,4.14111568 1.57692308,5.35897436 L1.57692308,19.6923077 C1.57692308,20.9101664 2.5641926,21.8974359 3.78205128,21.8974359 L10.063359,21.8974359"
                    id="Path"
                    stroke={stroke}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <polyline
                    id="Path"
                    stroke={stroke}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="17.0128205 11.5897436 17.0128205 17.0897436 21.5 20"
                  />
                  <path
                    d="M17.0128205,8.37179487 L17.0128205,5.35897436 C17.0128205,4.14111568 16.025551,3.15384615 14.8076923,3.15384615 L12.6025641,3.15384615"
                    id="Path"
                    stroke={stroke}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    id="Rectangle"
                    stroke={stroke}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    x="5.98717949"
                    y="1.5"
                    width="6.61538462"
                    height="3.30769231"
                    rx="1"
                  />
                  <circle
                    id="Oval"
                    stroke={stroke}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    cx="17.0128205"
                    cy="17.0897436"
                    r="5.5"
                  />
                  <line
                    x1="4.98717949"
                    y1="9.37179487"
                    x2="11.6025641"
                    y2="9.37179487"
                    id="Path"
                    stroke={stroke}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <line
                    x1="4.98717949"
                    y1="13.7820513"
                    x2="8.29487179"
                    y2="13.7820513"
                    id="Path"
                    stroke={stroke}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
IconFinancial.defaultProps = {
  fill: 'none',
  stroke: 'none',
  width: '24px',
  height: '24px',
}
