import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsSharpIcon from '@mui/icons-material/NotificationsSharp'
import { AppBar, Box, IconButton, Toolbar, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useWebSocket from 'src/hooks/useWebSocket'
import { Configuration, NotificationsApi, Profile } from '../../api'
import logo from '../../assets/GrowthEco_Logo_761x150.png'
import onHandleLoading from '../../redux/actions/loading.action'
import setSnackbar from '../../redux/actions/snackbar.action'
import { getUserProfile } from '../../redux/actions/userProfil.action'
import { RootState, useAppDispatch } from '../../redux/store'
import handleAccessToken from '../../services/config'
import ApiStateHandler from '../apiStateHandler'
import ButtonRounded from '../global/buttonRounded'

interface IProps {
  handleDrawerToggle: () => void
  mobileOpen: boolean
}

export default function Header({ handleDrawerToggle, mobileOpen }: IProps) {
  const { t } = useTranslation()
  const { logout }: Auth0ContextInterface<User> = useAuth0()
  const [user, setUser] = useState<Profile | undefined>(undefined)
  const [countNotification, setCountNotification] = useState<number>(0)
  const [newNotification] = useWebSocket('/topic/notification')
  const [loading, setLoading] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const notificationReaded = useSelector(
    (state: RootState) => state.notificationReducer.notificationReaded,
  )

  const { palette } = useTheme()
  const navigate = useNavigate()

  const LoadNotificationCount = useCallback(() => {
    const notification = new NotificationsApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: handleAccessToken.getAccessTokenSilently(),
      }),
    )
    notification
      .getNotificationsCount('fr')
      .then((res) => {
        setCountNotification(res.data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        dispatch(setSnackbar(true, 'error', (err as Error).message))
      })
  }, [dispatch])

  useEffect(() => {
    LoadNotificationCount()
  }, [LoadNotificationCount, newNotification])
  useEffect(() => {
    dispatch(getUserProfile()).then((res) => {
      setUser(res.data)
    })
  }, [dispatch])
  useEffect(() => {
    if (user) {
      dispatch(onHandleLoading(false))
    }
  }, [dispatch, user])
  useEffect(() => {
    if (notificationReaded) {
      setCountNotification(0)
    }
  }, [notificationReaded])

  const handleLogout = () => {
    localStorage.removeItem('user')
    logout({
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: window.location.origin,
    })
  }
  const navigateToNotification = () => {
    navigate('/notifications')
  }
  return (
    <ApiStateHandler loading={loading}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, justifyContent: 'center' }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ ml: 2, display: { md: 'none' } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{
                borderColor: (theme) => theme.palette.beige,
                borderWidth: 2,
                borderStyle: 'solid',
                display: mobileOpen ? 'none' : 'flex',
              }}
            >
              <MenuIcon color="primary" />
            </IconButton>

            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={handleDrawerToggle}
              sx={{
                borderColor: (theme) => theme.palette.beige,
                borderWidth: 2,
                borderStyle: 'solid',
                display: mobileOpen ? 'flex' : 'none',
              }}
            >
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
          <Box>
            <img
              src={logo}
              width={150}
              alt="Logo GrowthEco"
              style={{
                float: 'none',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {user && (
              <Typography
                sx={{
                  fontSize: 18,
                  letterSpacing: 0.72,
                  lineHeight: '21px',
                  color: (theme) => theme.palette.text.primary,
                  display: { md: 'flex', xs: 'none' },
                }}
              >{`${t('header.hi')} ${user?.firstName} ${user?.lastName}`}</Typography>
            )}
            <IconButton
              sx={{
                borderColor: (theme) => theme.palette.beige,
                marginLeft: 2,
                borderWidth: 2,
                borderStyle: 'solid',
                position: 'relative',
              }}
              onClick={navigateToNotification}
            >
              <span
                style={{
                  position: 'absolute',
                  display: 'flex',
                  top: '-10px',
                  right: '-10px',
                  height: '24px',
                  width: '31.5px',
                  borderRadius: '12px',
                  backgroundColor: '#FD2859',
                  color: '#FFFFFF',
                  fontSize: '18px',
                  letterSpacing: '0.17px',
                  lineHeight: '21px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {countNotification}
              </span>
              <NotificationsSharpIcon color="primary" />
            </IconButton>
            <ButtonRounded
              handleAction={handleLogout}
              display={{ md: 'flex', xs: 'none' }}
              title={t('header.signout')}
              borderColor={palette.roundButton}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </ApiStateHandler>
  )
}
