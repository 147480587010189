import React from 'react'

export default function IconFacebook(): JSX.Element {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>FacebookRounded</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="footer"
          transform="translate(-1310.000000, -16.000000)"
          fill="#B5B4AB"
          fillRule="nonzero"
        >
          <g id="FacebookRounded" transform="translate(1310.000000, 16.000000)">
            <path d="M30,15.037594 C30,6.73684211 23.28,0 15,0 C6.72,0 0,6.73684211 0,15.037594 C0,22.3157895 5.16,28.3759398 12,29.7744361 L12,19.5488722 L9,19.5488722 L9,15.037594 L12,15.037594 L12,11.2781955 C12,8.37593985 14.355,6.01503759 17.25,6.01503759 L21,6.01503759 L21,10.5263158 L18,10.5263158 C17.175,10.5263158 16.5,11.2030075 16.5,12.0300752 L16.5,15.037594 L21,15.037594 L21,19.5488722 L16.5,19.5488722 L16.5,30 C24.075,29.2481203 30,22.8421053 30,15.037594 Z" />
          </g>
        </g>
      </g>
    </svg>
  )
}
IconFacebook.defaultProps = {
  fill: 'none',
  stroke: 'none',
  width: '30px',
  height: '30px',
}
