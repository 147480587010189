import React from 'react'
import { Props } from '../props.interface'

export default function IconMortgage({ fill, width, height }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon/hypotheque</title>
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2--1--Profil" transform="translate(-31.000000, -402.000000)">
          <g id="menu" transform="translate(29.000000, 177.000000)">
            <g id="Group" transform="translate(0.000000, 5.000000)">
              <g id="mi:home" transform="translate(0.000000, 218.000000)">
                <rect id="ViewBox" x="0" y="0" width="24" height="24" />
                <path
                  d="M11.336,2.253 C11.7147722,1.91664343 12.2852278,1.91664343 12.664,2.253 L21.664,10.253 C22.055076,10.6245046 22.0810371,11.2392243 21.722677,11.6423795 C21.3643168,12.0455347 20.7507928,12.0918232 20.336,11.747 L20,11.45 L20,19 C20,20.1045695 19.1045695,21 18,21 L6,21 C4.8954305,21 4,20.1045695 4,19 L4,11.45 L3.664,11.747 C3.24920717,12.0918232 2.63568321,12.0455347 2.27732304,11.6423795 C1.91896287,11.2392243 1.94492401,10.6245046 2.336,10.253 L11.336,2.253 Z M6,9.67 L6,19 L9,19 L9,14 C9,13.4477153 9.44771525,13 10,13 L14,13 C14.5522847,13 15,13.4477153 15,14 L15,19 L18,19 L18,9.671 L12,4.338 L6,9.671 L6,9.67 Z M13,19 L13,15 L11,15 L11,19 L13,19 Z"
                  id="Shape"
                  fill={fill}
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
IconMortgage.defaultProps = {
  fill: 'none',
  stroke: 'none',
  width: '20px',
  height: '19px',
}
