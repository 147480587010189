import React from 'react'
import { Props } from '../props.interface'

export default function IconProfil({ fill, width, height }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon/profil</title>
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="8--Hypothèque" transform="translate(-30.000000, -667.000000)">
          <g id="menu" transform="translate(29.000000, 151.000000)">
            <g id="Group" transform="translate(0.000000, 5.000000)">
              <g id="icon/profil" transform="translate(0.000000, 510.000000)">
                <rect id="ViewBox" x="0" y="0" width="24" height="24" />
                <path
                  d="M12,6 C9.92893219,6 8.25,7.67893219 8.25,9.75 C8.25,11.8210678 9.92893219,13.5 12,13.5 C14.0710678,13.5 15.75,11.8210678 15.75,9.75 C15.75,7.67893219 14.0710678,6 12,6 L12,6 Z"
                  id="Path"
                  fill={fill}
                  fillRule="nonzero"
                />
                <path
                  d="M12,1.5 C6.20101013,1.5 1.5,6.20101013 1.5,12 C1.5,17.7989899 6.20101013,22.5 12,22.5 C17.7989899,22.5 22.5,17.7989899 22.5,12 C22.493387,6.20375151 17.7962485,1.50661295 12,1.5 Z M17.994,18.6945 C17.9629029,16.647814 16.2969186,15.003856 14.25,15 L9.75,15 C7.70308138,15.003856 6.03709715,16.647814 6.006,18.6945 C3.22155815,16.2082175 2.26095068,12.2611461 3.59147859,8.77339428 C4.92200651,5.28564247 8.26745158,2.98126916 12.000375,2.98126916 C15.7332984,2.98126916 19.0787435,5.28564247 20.4092714,8.77339428 C21.7397993,12.2611461 20.7791918,16.2082175 17.99475,18.6945 L17.994,18.6945 Z"
                  id="Shape"
                  stroke={fill}
                  strokeWidth="0.5"
                  fill={fill}
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
IconProfil.defaultProps = {
  fill: 'none',
  stroke: 'none',
  width: '22px',
  height: '22px',
}
