import { Box, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { NotificationSearchResultContent } from '../../../api'
import ButtonRounded from '../../global/buttonRounded'
import CardListNotification from './cardListNotification'
import CardNavigatorTitle from './cardNavigatorTitle'

interface IProps {
  notifications: Array<NotificationSearchResultContent>
}
export default function CardNotification({ notifications }: IProps) {
  const { palette } = useTheme()
  const navigate = useNavigate()

  const handleNavigateNotification = () => {
    navigate('/notifications')
  }
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: { sm: 'flex', xs: 'none' },
        width: { sm: '50%', xs: '0' },
        height: 'auto',
        padding: 5,
        flexDirection: 'column',
        borderBottomStyle: 'solid',
        borderBottomWidth: '5px',
        borderBottomColor: palette.primary.main,
        boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
        borderRadius: '5px 5px 5px 5px',
        backgroundColor: '#fff',
      }}
    >
      <CardNavigatorTitle />
      <CardListNotification notifications={notifications} />

      <ButtonRounded
        handleAction={handleNavigateNotification}
        display={{ sm: 'flex' }}
        title={t('notification.button')}
        borderColor={palette.primary.main}
        style={{
          maxWidth: '320px',
          ml: 0,
          minHeight: '53px',
        }}
      />
    </Box>
  )
}
