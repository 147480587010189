/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { DocumentContent } from '../components/documents/documentContent.inteface'

const formatAndGroup = <T extends DocumentContent>(dataObj: Array<T>, field: string) => {
  const result = dataObj.reduce((r, a) => {
    r[a[field as keyof DocumentContent]!] = r[a[field as keyof DocumentContent]!] || []
    r[a[field as keyof DocumentContent]!].push(a)
    return r
  }, Object.create(null))

  const array: T[][] = Object.keys(result).map((key) => {
    const sorted = result[key].sort((a: T, b: T) => {
      if (a.lastModifiedDate == null && b.lastModifiedBy) return 0
      if (a.lastModifiedDate == null) return -1
      if (b.lastModifiedDate == null) return 1
      return new Date(b.lastModifiedDate!).getTime() - new Date(a.lastModifiedDate!).getTime()
    })

    return sorted
  })
  return array
}

const format = <T extends DocumentContent>(dataObj: Array<T>) => {
  return [
    dataObj.sort((a: T, b: T) => {
      if (a.lastModifiedDate == null && b.lastModifiedBy) return 0
      if (a.lastModifiedDate == null) return -1
      if (b.lastModifiedDate == null) return 1
      return new Date(b.lastModifiedDate!).getTime() - new Date(a.lastModifiedDate!).getTime()
    }),
  ]
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

const formatDate = (datev: string) => {
  const date = new Date(datev)
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
    '/',
  )
}

export { formatAndGroup, format, formatDate }
