import { SET_SNACKBAR } from '../actionTypes'
import { ISnackbar } from '../types'

const init: ISnackbar = {
  snackbarOpen: false,
  snackbarType: 'success',
  snackbarMessage: '',
}
export default function snackbarReducer(
  state = init,
  { type, snackbarOpen, snackbarMessage, snackbarType }: any = {},
) {
  switch (type) {
    case SET_SNACKBAR:
      return {
        ...state,
        snackbarOpen,
        snackbarType,
        snackbarMessage,
      }

    default:
      return state
  }
}
