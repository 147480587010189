import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from '../auth/protected'
import Loader from '../components/loader'
import RoutesListDocuments from '../screens/documents/listDocumentNavigation'
import RoutesList, { IRoutes } from './routesList'

const DashboardScreen = React.lazy(() => import('../screens/dashboard'))
const NotifcationsScreen = React.lazy(() => import('../screens/notifications'))

export default function MainRouter() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<Loader loading />}>
            <ProtectedRoute component={DashboardScreen} />
          </React.Suspense>
        }
      />
      <Route
        path="/notifications"
        element={
          <React.Suspense fallback={<Loader loading />}>
            <ProtectedRoute component={NotifcationsScreen} />
          </React.Suspense>
        }
      />

      {RoutesList.map((route: IRoutes) => (
        <Route
          path={route.path}
          key={route.path}
          element={
            route.protected ? (
              <React.Suspense fallback={<Loader loading />}>
                <ProtectedRoute component={route.component} />
              </React.Suspense>
            ) : (
              <React.Suspense fallback={<Loader loading />}>
                <route.component />
              </React.Suspense>
            )
          }
        />
      ))}
      {RoutesListDocuments.map((route: IRoutes) => (
        <Route
          path={route.path}
          key={route.path}
          element={
            route.protected ? (
              <React.Suspense fallback={<Loader loading />}>
                <ProtectedRoute component={route.component} />
              </React.Suspense>
            ) : (
              <React.Suspense fallback={<Loader loading />}>
                <route.component />
              </React.Suspense>
            )
          }
        />
      ))}
    </Routes>
  )
}
