import React from 'react'
import { Props } from '../props.interface'

export default function IconDashboard({ fill, width, height }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon/dashboard</title>
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2--1--Profil" transform="translate(-30.000000, -185.000000)">
          <g id="menu" transform="translate(29.000000, 177.000000)">
            <g id="bx:tachometer" transform="translate(0.000000, 4.000000)">
              <rect id="ViewBox" x="0" y="0" width="24" height="24" />
              <path
                d="M11.9999969,4 C5.93460054,4 0.999996857,8.93459985 0.999996857,15 C0.998574639,16.6977138 1.39449921,18.3721997 2.15610066,19.8894995 C2.34310065,20.2634995 2.72370064,20.5 3.14060063,20.5 L20.8583001,20.5 C21.2752001,20.5 21.6569001,20.2634995 21.8439001,19.8894995 C22.6055015,18.3721997 23.0014261,16.6977138 22.9999969,15 C22.9999969,8.93459985 18.0654002,4 11.9999969,4 Z M20.1510001,18.2999996 L3.84900061,18.2999996 C3.41915681,17.2529618 3.19866862,16.1318354 3.19999463,14.9999997 C3.19999463,10.1478998 7.14790051,6.19999993 12.0000004,6.19999993 C16.8521002,6.19999993 20.8000061,10.1478998 20.8000061,14.9999997 C20.8013321,16.1318354 20.5808439,17.2529618 20.1510001,18.2999996 Z"
                id="Shape"
                fill={fill}
                fillRule="nonzero"
              />
              <path
                d="M10.1432963,14.1091121 C9.58366715,14.4750841 9.24638865,15.0986446 9.24638865,15.7673151 C9.24638865,16.4359856 9.58366715,17.0595461 10.1432963,17.4255181 C10.5829072,17.7147737 11.1195316,17.8172587 11.6347993,17.7103668 C12.1500669,17.6034749 12.601643,17.2959883 12.8899175,16.8557334 L18.0205697,9.03673338 C18.028943,9.02278563 18.028943,9.00535733 18.0205697,8.99140959 C18.007879,8.97205046 17.982016,8.96624556 17.9622962,8.97845994 L10.1432963,14.1091121 L10.1432963,14.1091121 Z"
                id="Path"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

IconDashboard.defaultProps = {
  fill: 'none',
  stroke: 'none',
  width: '22px',
  height: '17px',
}
