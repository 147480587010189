/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationSearchResultContent, ProductType } from '../../../../api'
import { icons } from '../../../../data/data'
import CardItemNotifcation from './cardItemNotification'

interface IProps {
  notifications: Array<NotificationSearchResultContent>
}
interface INotificationList {
  content: string | undefined
  date: string
  title: ProductType | undefined
  icon: any
  id: number
  isRead: boolean
}
export default function CardListNotification({ notifications }: IProps) {
  const { palette } = useTheme()
  const [notificationsList, setNotificationsList] = useState<INotificationList[]>()
  const { t } = useTranslation()

  React.useEffect(() => {
    if (notifications.length > 0) {
      const result = notifications.map((elem) => ({
        content: elem.message,
        date: elem.createdDate,
        title: elem.productType,
        icon: icons[elem.productType ? elem.productType : 'GENERAL'],
        id: elem.id,
        isRead: elem.isRead,
      }))
      setNotificationsList(result)
    } else {
      setNotificationsList([])
    }
  }, [notifications])
  return (
    <Box
      sx={{
        mt: 8,
        mb: 6,
      }}
    >
      {notificationsList?.length === 0 && (
        <Typography variant="h5" sx={{ mb: 15 }}>
          {t('blank_page.notifications')}
        </Typography>
      )}
      {notificationsList?.map((item) => (
        <CardItemNotifcation
          content={item.content}
          date={item.date}
          title={item.title}
          icon={
            <item.icon
              fill={palette.primary.main}
              stroke={palette.primary.main}
              width="24px"
              height="24px"
            />
          }
          key={item.id}
          isRead={item.isRead}
        />
      ))}
    </Box>
  )
}
