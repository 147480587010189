/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { AppointmentEmailPost, Configuration, EmailApi } from '../../api'
import i18n from '../../helpers/i18n'
import setSnackbar from '../../redux/actions/snackbar.action'
import { useAppDispatch } from '../../redux/store'
import handleAccessToken from '../../services/config'
import BasicModal from '../global/basicModal'
import RequestAnAppointementForm from '../RequestAnAppointementForm'

const schema = yup.object().shape({
  productType: yup.string().required(i18n.t('errors.required_field')),
  availableDate: yup.string().required(i18n.t('errors.required_field')),
  availableTimeSlot: yup.string().required(i18n.t('errors.required_field')),
  availableDate2: yup.string().required(i18n.t('errors.required_field')),
  availableTimeSlot2: yup.string().required(i18n.t('errors.required_field')),
})

interface IProps {
  modal: boolean
  openRequestAnAppointement: boolean
  handlecloseRequestAnAppointement: () => void
}

export default function RequestAnAppointement({
  modal,
  openRequestAnAppointement,
  handlecloseRequestAnAppointement,
}: IProps) {
  const methods = useForm<AppointmentEmailPost>({ resolver: yupResolver(schema) })
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch()

  const handleSumbitForm = (d: any) => {
    setLoading(true)
    if (
      new Date(d.availableDate).getDate() === new Date(d.availableDate2).getDate() &&
      d.availableTimeSlot === d.availableTimeSlot2
    ) {
      dispatch(setSnackbar(true, 'error', t('errors.same_date')))
      setLoading(false)
    } else {
      const emailApi = new EmailApi(
        new Configuration({
          basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
          accessToken: handleAccessToken.getAccessTokenSilently(),
        }),
      )

      emailApi
        .sendAppointmentRequestEmail('fr', d)
        .then(() => {
          dispatch(setSnackbar(true, 'success', t('request_an_appointement.success')))
          setLoading(false)
          handlecloseRequestAnAppointement()
        })
        .catch((err: Error) => {
          dispatch(setSnackbar(true, 'error', err.message))
        })
        .finally(() => setLoading(false))
    }
  }

  return modal ? (
    <BasicModal
      open={openRequestAnAppointement}
      handleClose={handlecloseRequestAnAppointement}
      title={t('request_an_appointement.title')}
      showActionSection
      handleAction={methods.handleSubmit((d) => handleSumbitForm(d))}
    >
      <Box
        sx={{
          maxHeight: { sm: '100%', xs: '450px' },
          overflowY: { sm: 'visible', xs: 'scroll' },
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <RequestAnAppointementForm
          loading={loading}
          handleAction={methods.handleSubmit((d) => handleSumbitForm(d))}
          methods={methods}
          setLoading={setLoading}
          showActionButton={false}
        />
      </Box>
    </BasicModal>
  ) : (
    <RequestAnAppointementForm
      loading={loading}
      handleAction={methods.handleSubmit((d) => handleSumbitForm(d))}
      methods={methods}
      setLoading={setLoading}
      showActionButton
    />
  )
}
