import React from 'react'
import { Props } from '../props.interface'

export default function IconQuestion({ fill, width, height }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>PermPhoneMsgRounded</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="bandeau-cta-v2" transform="translate(-492.000000, -39.000000)">
          <g id="v-1" transform="translate(489.000000, 36.000000)">
            <rect id="ViewBox" x="0" y="0" width="40" height="40" />
            <path
              d="M20,3.33333333 C29.205,3.33333333 36.6666667,10.795 36.6666667,20 C36.6666667,29.205 29.205,36.6666667 20,36.6666667 C17.3037112,36.6702307 14.6471357,36.0169557 12.26,34.7633333 L5.11,36.625 C4.62388153,36.751609 4.10706735,36.6113193 3.75169118,36.2562858 C3.396315,35.9012523 3.25552676,35.3845737 3.38166667,34.8983333 L5.24166667,27.75 C3.98466652,25.3603371 3.32961819,22.7000971 3.33331762,20 C3.33331762,10.795 10.795,3.33333333 20,3.33333333 Z M20,25.8333333 C19.0795254,25.8333333 18.3333333,26.5795254 18.3333333,27.5 C18.3333333,28.4204746 19.0795254,29.1666667 20,29.1666667 C20.9204746,29.1666667 21.6666667,28.4204746 21.6666667,27.5 C21.6666667,26.5795254 20.9204746,25.8333333 20,25.8333333 Z M20,11.25 C17.4686949,11.25 15.4166667,13.3020282 15.4166667,15.8333333 C15.4168681,16.4904736 15.9258546,17.0352526 16.5814667,17.0800432 C17.2370787,17.1248339 17.8154275,16.6543403 17.905,16.0033333 L17.9283333,15.62 C18.045213,14.5197171 19.0018185,13.7027394 20.1068363,13.7594752 C21.2118542,13.816211 22.0797565,14.7268658 22.0833333,15.8333333 C22.0833333,16.7316667 21.8583333,17.175 21.0083333,18.0533333 L20.5016667,18.57 C19.245,19.8766667 18.75,20.815 18.75,22.5 C18.75,23.1903559 19.3096441,23.75 20,23.75 C20.6903559,23.75 21.25,23.1903559 21.25,22.5 C21.25,21.6016667 21.475,21.1583333 22.325,20.28 L22.8316667,19.7633333 C24.0883333,18.4566667 24.5833333,17.5183333 24.5833333,15.8333333 C24.5833333,13.3020282 22.5313051,11.25 20,11.25 Z"
              id="Combined-Shape"
              fill={fill}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
IconQuestion.defaultProps = {
  fill: 'none',
  stroke: 'none',
  width: '34px',
  height: '34px',
}
