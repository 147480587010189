import IconAccounting from '../components/icons/accounting'
import IconDashboard from '../components/icons/dashboard'
import IconFinancial from '../components/icons/financial'
import IconInsurance from '../components/icons/insurance'
import IconInvestement from '../components/icons/investement'
import IconMortgage from '../components/icons/mortgage'
import i18n from '../helpers/i18n'
import { Insurance } from '../helpers/types'

const notificaitonList = [
  {
    title: 'Plan financier',
    icon: IconFinancial,
    content: 'Publication d’un nouveau plan financier par votre conseiller.',
    date: '7 mars 2022',
  },
  {
    title: 'Placements',
    icon: IconInvestement,
    content: 'C’est la période des REER Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    date: '3 mars 2022',
  },
]

const listActionType = [
  {
    value: 'ADD',
    label: i18n.t(`investements.portfolio_management.listActionType.ADD`),
  },
  {
    value: 'UPDATE',
    label: i18n.t(`investements.portfolio_management.listActionType.UPDATE`),
  },
  {
    value: 'DELETE',
    label: i18n.t(`investements.portfolio_management.listActionType.DELETE`),
  },
]

const listAccountType = [
  {
    value: 'TFSA',
    label: i18n.t(`investements.portfolio_management.listAccountType.TFSA`),
  },
  {
    value: 'RRSP',
    label: i18n.t(`investements.portfolio_management.listAccountType.RRSP`),
  },
  {
    value: 'RESP',
    label: i18n.t(`investements.portfolio_management.listAccountType.RESP`),
  },
  {
    value: 'LIRA',
    label: i18n.t(`investements.portfolio_management.listAccountType.LIRA`),
  },
  {
    value: 'LIF',
    label: i18n.t(`investements.portfolio_management.listAccountType.LIF`),
  },
  {
    value: 'RRIF',
    label: i18n.t(`investements.portfolio_management.listAccountType.RRIF`),
  },
  {
    value: 'NON_REGISTERED_CAD',
    label: i18n.t(`investements.portfolio_management.listAccountType.NON_REGISTERED_CAD`),
  },
  {
    value: 'NON_REGISTERED_USD',
    label: i18n.t(`investements.portfolio_management.listAccountType.NON_REGISTERED_USD`),
  },
  {
    value: 'MARGIN',
    label: i18n.t(`investements.portfolio_management.listAccountType.MARGIN`),
  },
  {
    value: 'NON_REGISTERED_BUSINESS',
    label: i18n.t(`investements.portfolio_management.listAccountType.NON_REGISTERED_BUSINESS`),
  },
]

const listPortfolioType = [
  {
    value: 'SECURE',
    label: i18n.t(`investements.portfolio_management.listPortfolioType.SECURE`),
  },
  {
    value: 'MODERATE_GROWTH',
    label: i18n.t(`investements.portfolio_management.listPortfolioType.MODERATE_GROWTH`),
  },
  {
    value: 'BALANCED',
    label: i18n.t(`investements.portfolio_management.listPortfolioType.BALANCED`),
  },
  {
    value: 'GROWTH',
    label: i18n.t(`investements.portfolio_management.listPortfolioType.GROWTH`),
  },
  {
    value: 'ACTIONS',
    label: i18n.t(`investements.portfolio_management.listPortfolioType.ACTIONS`),
  },
]

const listTimeHorizon = [
  {
    value: 'YEAR_1_2',
    label: i18n.t(`investements.portfolio_management.listTimeHorizon.YEAR_1_2`),
  },
  {
    value: 'YEAR_3_5',
    label: i18n.t(`investements.portfolio_management.listTimeHorizon.YEAR_3_5`),
  },
  {
    value: 'YEAR_6_9',
    label: i18n.t(`investements.portfolio_management.listTimeHorizon.YEAR_6_9`),
  },
  {
    value: 'YEAR_10_PLUS',
    label: i18n.t(`investements.portfolio_management.listTimeHorizon.YEAR_10_PLUS`),
  },
]

const listPACActionType = [
  {
    value: 'UNIQUE_CONTRIBUTION',
    label: i18n.t(`investements.PAC_direct_debit.listPACActionType.UNIQUE_CONTRIBUTION`),
  },
  {
    value: 'CREATE',
    label: i18n.t(`investements.PAC_direct_debit.listPACActionType.CREATE`),
  },
  {
    value: 'UPDATE',
    label: i18n.t(`investements.PAC_direct_debit.listPACActionType.UPDATE`),
  },
  {
    value: 'STOP',
    label: i18n.t(`investements.PAC_direct_debit.listPACActionType.STOP`),
  },
  {
    value: 'SUSPEND',
    label: i18n.t(`investements.PAC_direct_debit.listPACActionType.SUSPEND`),
  },
]

const listPACFrequency = [
  {
    value: 'ONCE',
    label: i18n.t(`investements.PAC_direct_debit.listPACFrequency.ONCE`),
  },
  {
    value: 'WEEKLY',
    label: i18n.t(`investements.PAC_direct_debit.listPACFrequency.WEEKLY`),
  },
  {
    value: 'BI_WEEKLY',
    label: i18n.t(`investements.PAC_direct_debit.listPACFrequency.BI_WEEKLY`),
  },
  {
    value: 'SEMI_MONTHLY',
    label: i18n.t(`investements.PAC_direct_debit.listPACFrequency.SEMI_MONTHLY`),
  },
  {
    value: 'MONTHLY',
    label: i18n.t(`investements.PAC_direct_debit.listPACFrequency.MONTHLY`),
  },
]

const listInsurance: Insurance[] = [
  {
    type: 'LIFE',
    visible: false,
    description: '',
  },
  {
    type: 'DISABILITY',
    visible: false,
    description: '',
  },
  {
    type: 'CRITICAL_ILLNESS',
    visible: false,
    description: '',
  },
  {
    type: 'GROUP',
    visible: false,
    description: '',
  },
  {
    type: 'CHILD',
    visible: false,
    description: '',
  },
  {
    type: 'CAR',
    visible: false,
    description: '',
  },
  {
    type: 'HOME',
    visible: false,
    description: '',
  },
]

const icons = {
  INVESTMENT: IconInvestement,
  INSURANCE: IconInsurance,
  MORTGAGE: IconMortgage,
  ACCOUNTING: IconAccounting,
  GENERAL: IconDashboard,
}

const listProductType = [
  {
    value: 'INVESTMENT',
    label: i18n.t('product_type.INVESTMENT'),
  },
  {
    value: 'INSURANCE',
    label: i18n.t('product_type.INSURANCE'),
  },
  {
    value: 'MORTGAGE',
    label: i18n.t('product_type.MORTGAGE'),
  },
  {
    value: 'ACCOUNTING',
    label: i18n.t('product_type.ACCOUNTING'),
  },
  {
    value: 'GENERAL',
    label: i18n.t('product_type.GENERAL'),
  },
]

const fill = () => {
  // prettier-ignore
  listActionType.forEach((key, index) => {
    listActionType[index].label = i18n.t(`investements.portfolio_management.listActionType.${key.value}`)
  })

  // prettier-ignore
  listAccountType.forEach((key, index) => {
    listAccountType[index].label = i18n.t(`investements.portfolio_management.listAccountType.${key.value}`)
  })

  // prettier-ignore
  listPortfolioType.forEach((key, index) => {
    listPortfolioType[index].label = i18n.t(`investements.portfolio_management.listPortfolioType.${key.value}`)
  })

  // prettier-ignore
  listTimeHorizon.forEach((key, index) => {
    listTimeHorizon[index].label = i18n.t(`investements.portfolio_management.listTimeHorizon.${key.value}`)
  })

  // prettier-ignore
  listPACActionType.forEach((key, index) => {
    listPACActionType[index].label = i18n.t(`investements.PAC_direct_debit.listPACActionType.${key.value}`)
  })

  // prettier-ignore
  listPACFrequency.forEach((key, index) => {
    listPACFrequency[index].label = i18n.t(`investements.PAC_direct_debit.listPACFrequency.${key.value}`)
  })

  // prettier-ignore
  listProductType.forEach((key, index) => {
    listProductType[index].label = i18n.t(`product_type.${key.value}`)
  })
}

i18n.on('languageChanged', () => {
  fill()
})

export default notificaitonList

export {
  listActionType,
  listAccountType,
  listTimeHorizon,
  listPACActionType,
  listPACFrequency,
  listInsurance,
  icons,
  listProductType,
  listPortfolioType,
}
