import { Box, ButtonBase, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  icon: JSX.Element
  path: string
  content: string | undefined
  index: number
  length: number
  height: string
  width: string
  document?: boolean
}

export default function CardNavigator({
  icon,
  path,
  content,
  index,
  length,
  height,
  width,
  document,
}: Props) {
  const { palette } = useTheme()
  const navigate = useNavigate()
  return (
    <ButtonBase
      onClick={() => navigate(path)}
      sx={{
        borderBottomStyle: 'solid',
        borderBottomWidth: '5px',
        borderBottomColor: palette.primary.main,
        filter: 'drop-shadow(0 0 3px rgba(0, 0, 0, 0.5))',
        display: 'inline-block',
        borderRadius: '0px 0px 5px 5px',
        mb: (index === length - 2 && !document) || (index === length - 1 && !document) ? 0 : 5,
        height,
        width,
        ml: document ? 2 : 0,
      }}
    >
      <Box
        sx={{
          //  height: { lg: '20px', xs: '12px' },
          //  width: { lg: '100px', xs: '55px' },
          height: '14%',
          width: '40%',
          backgroundColor: '#fff',
          clipPath: 'polygon(10% 0, 90% 0, 100% 100%, 0 100%)',
          ml: 1,
          borderTopLeftRadius: '28px',
          borderTopRightRadius: '28px',
        }}
      />
      <Box
        sx={{
          height: '87%',
          width: 'auto',

          backgroundColor: '#fff',
          borderRadius: '5px 5px 0px 0px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItem: 'center',
          alignContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 1,
            mt: 1,
          }}
        >
          {icon}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: { sm: '20px', xs: '12px' },
              lineHeight: { sm: '24px', xs: '10px' },
              letter: { sm: '0.83px', xs: '0.4px' },
              mr: 1,
              ml: 1,
              align: 'center',
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {content}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  )
}

CardNavigator.defaultProps = {
  document: false,
}
