import { Alert, Box, Snackbar } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import setSnackbar from '../../../redux/actions/snackbar.action'
import { RootState, useAppDispatch } from '../../../redux/store'

function CustomizedSnackbars() {
  const dispatch = useAppDispatch()

  const snackbarOpen = useSelector((state: RootState) => state.snackbarReducer.snackbarOpen)
  const snackbarType = useSelector((state: RootState) => state.snackbarReducer.snackbarType)
  const snackbarMessage = useSelector((state: RootState) => state.snackbarReducer.snackbarMessage)
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setSnackbar(false, snackbarType, snackbarMessage))
  }

  return (
    <Box
      sx={{
        width: '100%',
        '& > * + *': {
          marginTop: (theme) => theme.spacing(2),
        },
      }}
    >
      <Snackbar
        open={snackbarOpen}
        onClose={handleClose}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert elevation={6} variant="filled" onClose={handleClose} color={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default CustomizedSnackbars
