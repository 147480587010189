import { Box, Toolbar } from '@mui/material'
import React from 'react'
import CustomizedSnackbars from '../global/snackbars/CustomizedSnackbars'

interface Props {
  children: React.ReactElement
}
export default function Main({ children }: Props) {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        backgroundColor: (theme) => theme.palette.grayBackground,
        minHeight: '100vh',
      }}
    >
      <Toolbar />
      <CustomizedSnackbars />
      {children}
      <Toolbar />
    </Box>
  )
}
