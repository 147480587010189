/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@mui/material'
import React from 'react'

interface IProps {
  handleAction?: () => void
  display: any
  title: string
  borderColor: string | undefined
  style?: any
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
}

export default function ButtonRounded({
  handleAction,
  display,
  title,
  borderColor,
  style,
  type,
  disabled,
}: IProps) {
  return (
    <Button
      color="inherit"
      onClick={handleAction}
      disabled={disabled}
      type={type}
      sx={{
        marginLeft: 2,
        borderWidth: 3,
        borderStyle: 'solid',
        borderRadius: 25,
        borderColor,
        fontSize: 18,
        letterSpacing: 0.8,
        textAlign: 'center',
        paddingInline: 2,
        lineHeight: '26px',
        display,
        ...style,
      }}
    >
      {title}
    </Button>
  )
}
ButtonRounded.defaultProps = {
  style: {},
  type: 'button',
  handleAction: () => {},
  disabled: false,
}
