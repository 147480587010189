import React from 'react'
import { Props } from '../props.interface'

export default function IconDocuments({ stroke, width, height }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon/documents</title>
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2--1--Profil" transform="translate(-33.000000, -621.000000)">
          <g id="menu" transform="translate(29.000000, 177.000000)">
            <g id="Group" transform="translate(0.000000, 5.000000)">
              <g id="Group" transform="translate(0.000000, 437.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                <path
                  d="M15,3 L15,7 C15,7.55228475 15.4477153,8 16,8 L20,8"
                  id="Path"
                  stroke={stroke}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18,17 L11,17 C9.8954305,17 9,16.1045695 9,15 L9,5 C9,3.8954305 9.8954305,3 11,3 L15,3 L20,8 L20,15 C20,16.1045695 19.1045695,17 18,17 Z"
                  id="Path"
                  stroke={stroke}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16,17 L16,19 C16,20.1045695 15.1045695,21 14,21 L7,21 C5.8954305,21 5,20.1045695 5,19 L5,9 C5,7.8954305 5.8954305,7 7,7 L9,7"
                  id="Path"
                  stroke={stroke}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

IconDocuments.defaultProps = {
  fill: 'none',
  stroke: 'none',
  width: '17px',
  height: '20px',
}
