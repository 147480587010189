import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react'
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useTheme,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import changeLanguage from 'src/helpers/changeLanguage'
import i18n from 'src/helpers/i18n'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import onHandleSelectedTab from '../../redux/actions/selectedTab.action'
import { RootState, useAppDispatch } from '../../redux/store'
import RoutesList, { IRoutes } from '../../routes/routesList'
import DashboardScreen from '../../screens/dashboard'
import ButtonLanguage from '../global/buttonLanguage'
import ButtonRounded from '../global/buttonRounded'
import IconDashboard from '../icons/dashboard'
import './style.css'

export const drawerWidth = 240
interface Props {
  mobileOpen: boolean
  handleDrawerToggle: () => void
}

export default function DrawerMenu({ mobileOpen, handleDrawerToggle }: Props) {
  const location = useLocation()
  const { t } = useTranslation()
  const { logout }: Auth0ContextInterface<User> = useAuth0()
  const dispatch = useAppDispatch()
  const selectedTab = useSelector((state: RootState) => state.selectedTabReducer.selectedTab)
  const [mobileWidth, setMobileWidth] = useState<number>(0)
  const { width } = useWindowDimensions()
  React.useEffect(() => {
    setMobileWidth(width)
  }, [width])

  const list = useMemo(
    () => [
      {
        path: '/',
        sidebarName: 'dashboard',
        component: DashboardScreen,
        protected: true,
        icon: IconDashboard,
        fill: true,
        stroke: false,
      },
      ...RoutesList,
    ],
    [],
  )
  React.useEffect(() => {
    dispatch(onHandleSelectedTab(list.findIndex((elem) => elem.path === location.pathname)))
  }, [dispatch, list, location, selectedTab])

  const navigate = useNavigate()
  const { palette } = useTheme()

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    path: string,
  ) => {
    dispatch(onHandleSelectedTab(index))
    if (mobileWidth < 900) {
      handleDrawerToggle()
    }
    navigate(path)
    window.scrollTo(0, 0)
  }

  const handleLogout = () => {
    localStorage.removeItem('user')
    logout({
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: window.location.origin,
    })
  }

  const drawer = (
    <Box sx={{ overflow: 'auto', mt: 5 }}>
      <Toolbar />
      {list.map(
        (route: IRoutes, index) =>
          route.sidebarName && (
            <List key={route.path} sx={{ marginLeft: 1 }}>
              <ListItemButton
                key={route.sidebarName}
                onClick={(event) => handleListItemClick(event, index, route.path)}
                selected={selectedTab === index}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.text.primary,
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: 35 }}>
                  {route.fill && (
                    <route.icon
                      fill={selectedTab === index ? palette.text.primary : palette.primary.main}
                    />
                  )}
                  {route.stroke && (
                    <route.icon
                      stroke={selectedTab === index ? palette.text.primary : palette.primary.main}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={t(`sidebar_names.${route.sidebarName}`)} />
              </ListItemButton>
            </List>
          ),
      )}
      <ButtonLanguage
        handleAction={() => changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')}
        title={t('sidebar_names.language')}
      />

      <ButtonRounded
        handleAction={handleLogout}
        display={{ sm: 'none', xs: 'flex' }}
        title={t('header.signout')}
        borderColor={palette.roundButton}
        style={{ marginBottom: '20px' }}
      />
      <Toolbar sx={{ backgroundColor: (theme) => theme.palette.grayBackground }} />
    </Box>
  )

  return (
    <Box
      component="nav"
      sx={{
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
      }}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  )
}
