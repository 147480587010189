// eslint-disable-next-line @typescript-eslint/no-explicit-any
let getAccessTokenSilently: any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setAccessToken(func: any) {
  getAccessTokenSilently = func
}

function getAccessToken(): Promise<string> {
  return getAccessTokenSilently()
}
const handleAccessToken = {
  getAccessTokenSilently: getAccessToken,
  setAccessTokenSilently: setAccessToken,
}
export default handleAccessToken
