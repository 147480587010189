import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    beige?: string
    grayBackground?: string
    roundButton?: string
    peak?: string
    white: string
    peakSecondary: string
  }
  interface PaletteOptions {
    beige?: string
    grayBackground?: string
    roundButton?: string
    peak?: string
    white: string
    peakSecondary: string
  }
}
// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ['"Alegreya Sans"', '"sans-serif"'].join(','),
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    h1: {
      fontWeight: 500,
      fontSize: '3rem',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'white',
        },
        root: {
          height: '100px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '100px',
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderBottomStyle: 'none',
          borderRadius: '5px 5px 0 0',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderRadius: '0 0 5px 5px',
          borderBottomStyle: 'solid',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#86BC64',
      light: '#90C86D',
      dark: '#85BB63',
    },
    secondary: {
      main: '#f50057',
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: '#333333',
    },
    beige: '#F1EFE8',
    grayBackground: '#F5F5F5',
    roundButton: '#F1EFE8',
    peak: '#1C355E',
    white: '#FFFFFF',
    peakSecondary: '#0093B2',
  },
})

export default theme
