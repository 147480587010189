import { Box, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import RoutesList, { IRoutes } from '../../../routes/routesList'
import CardNavigator from '../cardNavigator'

export default function CardsNavigator() {
  const { t } = useTranslation()
  const { palette } = useTheme()
  const [mobile, setMobile] = useState<number>(0)
  const { width } = useWindowDimensions()
  React.useEffect(() => {
    setMobile(width)
  }, [width])

  const list = RoutesList.filter(
    (route: IRoutes) => route.path !== '/profil/*' && route.sidebarName !== undefined,
  )
  return (
    <Box
      sx={{
        display: 'flex',
        width: { sm: '50%', xs: '100%' },
        justifyContent: 'space-around',
        alignContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {list.map((route: IRoutes, index) => (
        <CardNavigator
          length={list.length}
          key={route.path}
          index={index}
          path={route.path}
          height="28.5%"
          width="40%"
          icon={
            <route.icon
              fill={palette.primary.main}
              stroke={palette.primary.main}
              width={mobile > 600 ? '40px' : '22px'}
              height={mobile > 600 ? '40px' : '22px'}
            />
          }
          content={t(`sidebar_names.${route.sidebarName}`)}
        />
      ))}
    </Box>
  )
}
