import { SELECTED_TAB } from '../actionTypes'
import { ISelectedTab } from '../types'

const init: ISelectedTab = {
  selectedTab: 0,
}
export default function selectedTabReducer(state = init, { type, payload }: any = {}) {
  switch (type) {
    case SELECTED_TAB:
      return {
        ...state,
        selectedTab: payload,
      }

    default:
      return state
  }
}
