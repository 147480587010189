import { Locale, Profile } from '../../api'
import { GET_PROFILE_USER_SUCCESS } from '../actionTypes'

const init: Profile = {
  email: '',
  firstName: '',
  id: 0,
  lastName: '',
  locale: Locale.Fr,
  communicationLocale: Locale.Fr,
  phoneNumber: '',
  profileType: undefined,
  inactiveReason: '',
  active: false,
  admin: false,
}
export default function userProfileReducer(state = init, { type, payload }: any = {}) {
  switch (type) {
    case GET_PROFILE_USER_SUCCESS:
      return {
        ...payload,
      }

    default:
      return state
  }
}
