import React from 'react'
import { Props } from '../props.interface'

export default function IconPhone({ fill, width, height }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icon/phone</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="bandeau-cta-v2" transform="translate(-927.000000, -42.000000)">
          <g id="Path" transform="translate(922.000000, 36.000000)">
            <polygon points="0 0 40 0 40 40 0 40" />
            <path
              d="M8.33333333,6.66666667 L15,6.66666667 L18.3333333,15 L14.1666667,17.5 C15.951597,21.1192177 18.8807823,24.048403 22.5,25.8333333 L25,21.6666667 L33.3333333,25 L33.3333333,31.6666667 C33.3333333,33.5076158 31.8409492,35 30,35 C16.546082,34.182399 5.81760103,23.453918 5,10 C5,8.15905083 6.49238417,6.66666667 8.33333333,6.66666667"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
IconPhone.defaultProps = {
  fill: 'none',
  stroke: 'none',
  width: '29px',
  height: '29px',
}
