// eslint-disable-next-line import/prefer-default-export
export const Equity = {
  True: true,
  False: false,
}
export const TimeSlotFr = {
  slot_one: '9h-12h',
  slot_two: '12h-15h',
  slot_three: '15h-18h',
}

export const TimeSlotEn = {
  slot_one: '9am-12pm',
  slot_two: '12pm-3pm',
  slot_three: '3pm-6pm',
}
