/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import IconAccounting from '../../components/icons/accounting'
import IconDocuments from '../../components/icons/documents'
import IconFinancial from '../../components/icons/financial'
import IconInsurance from '../../components/icons/insurance'
import IconInvestments from '../../components/icons/investement'
import IconMortgage from '../../components/icons/mortgage'
import { Props } from '../../components/icons/props.interface'

const AssessmentNotice = React.lazy(() => import('./assessmentNotice'))
const DriverLicense = React.lazy(() => import('./driverLicense'))
const FinancialPlan = React.lazy(() => import('./financialPlan'))
const InsurancePolicy = React.lazy(() => import('./insurancePolicy'))
const MortgageStatements = React.lazy(() => import('./mortgageStatements'))
const PowerOfAttorney = React.lazy(() => import('./powerOfAttorney'))
const RRSPContribution = React.lazy(() => import('./RRSP-contribution'))
const SpecimenCheck = React.lazy(() => import('./specimenCheck'))
const T1s = React.lazy(() => import('./t1s'))
const T4s = React.lazy(() => import('./t4s'))
const Testament = React.lazy(() => import('./Testament'))
const General = React.lazy(() => import('./general'))

export interface IRoutes {
  path: string
  sidebarName?: string
  component: any
  protected: boolean
  icon: ({ fill, stroke, width, height }: Props) => JSX.Element
  fill?: boolean
  stroke?: boolean
}

const RoutesListDocuments: IRoutes[] = [
  {
    path: '/documents/RRSP-contribution',
    sidebarName: 'RRSP_contribution',
    component: RRSPContribution,
    protected: true,
    icon: IconInvestments,
    fill: true,
    stroke: false,
  },
  {
    path: '/documents/insurance-policy',
    sidebarName: 'insurance_policy',
    component: InsurancePolicy,
    protected: true,
    icon: IconInsurance,
    fill: true,
    stroke: false,
  },
  {
    path: '/documents/mortgage-statements',
    sidebarName: 'mortgage_statements',
    component: MortgageStatements,
    protected: true,
    icon: IconMortgage,
    fill: true,
    stroke: false,
  },
  {
    path: '/documents/t4s',
    sidebarName: 't4s',
    component: T4s,
    protected: true,
    icon: IconAccounting,
    fill: true,
    stroke: false,
  },
  {
    path: '/documents/financial-plan',
    sidebarName: 'financial_plan',
    component: FinancialPlan,
    protected: true,
    icon: IconFinancial,
    fill: true,
    stroke: false,
  },

  {
    path: '/documents/driver-license',
    sidebarName: 'driver_license',
    component: DriverLicense,
    protected: true,
    icon: IconDocuments,
    fill: true,
    stroke: false,
  },
  {
    path: '/documents/testament',
    sidebarName: 'testament',
    component: Testament,
    protected: true,
    icon: IconDocuments,
    fill: true,
    stroke: false,
  },
  {
    path: '/documents/power-of-attorney',
    sidebarName: 'power_of_attorney',
    component: PowerOfAttorney,
    protected: true,
    icon: IconDocuments,
    fill: true,
    stroke: false,
  },
  {
    path: '/documents/speciement-check',
    sidebarName: 'speciement_check',
    component: SpecimenCheck,
    protected: true,
    icon: IconDocuments,
    fill: true,
    stroke: false,
  },
  {
    path: '/documents/t1s',
    sidebarName: 't1s',
    component: T1s,
    protected: true,
    icon: IconDocuments,
    fill: true,
    stroke: false,
  },
  {
    path: '/documents/assessment-notice',
    sidebarName: 'assessment_notice',
    component: AssessmentNotice,
    protected: true,
    icon: IconDocuments,
    fill: true,
    stroke: false,
  },
  {
    path: '/documents/general',
    sidebarName: 'general',
    component: General,
    protected: true,
    icon: IconDocuments,
    fill: true,
    stroke: false,
  },
]

export default RoutesListDocuments
