import { SET_PERMISSION } from '../actionTypes'
import { IPermission } from '../types'

const init: IPermission = {
  allowed: false,
}
export default function permissionReducer(state = init, { type, payload }: any = {}) {
  switch (type) {
    case SET_PERMISSION:
      return {
        ...state,
        allowed: payload,
      }

    default:
      return state
  }
}
