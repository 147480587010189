/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ProductType } from '../../../../../api'
import { formatDate } from '../../../../../helpers/function'
import listProductType from '../../../../../routes/listProductType'

interface IProps {
  title: ProductType | undefined
  icon: any
  content: string | undefined
  date: string
  isRead: boolean
}
export default function CardItemNotifcation({ title, icon, content, date, isRead }: IProps) {
  const { t } = useTranslation()
  return (
    <Link
      to={title ? listProductType[title] : '/'}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Box
        sx={{
          mb: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Box>{icon}</Box>

            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 'bold',
                letterSpacing: '1.07px',
                lineHeight: '18px',
                ml: 1,
              }}
            >
              {title ? t(`product_type.${title}`) : ''}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                size: '16px',
                lineHeight: '20px',
                letter: '0.15px',
              }}
            >
              {formatDate(date)}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '18px',
              letterSpacing: '0.17px',
              lineHeight: '24px',
              ml: 4,
              fontWeight: isRead ? '100' : 'bold',
            }}
          >
            {content}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{
          mb: 3,
        }}
      />
    </Link>
  )
}
