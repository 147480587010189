const paths = {
  investement: '/investement',
  insurance: '/insurance',
  mortgage: '/mortgage',
  accounting: '/accounting',
  financialPlan: '/financial-plan',
  documents: '/documents',
  profil: '/profil/*',
  permission: '/permission',
  general: '/',
  notifications: '/notifications',
}

export default paths
