import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, MenuItem, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import * as yup from 'yup'
import { Configuration, EmailApi, ProductType, QuestionEmailPost } from '../../api'
import ApiStateHandler from '../../components/apiStateHandler'
import BasicModal from '../../components/global/basicModal'
import { listProductType } from '../../data/data'
import i18n from '../../helpers/i18n'
import setSnackbar from '../../redux/actions/snackbar.action'
import { useAppDispatch } from '../../redux/store'
import paths from '../../routes/paths'
import handleAccessToken from '../../services/config'

const pathsListValue = {
  [paths.accounting]: ProductType.Accounting,
  [paths.general]: ProductType.General,
  [paths.investement]: ProductType.Investment,
  [paths.insurance]: ProductType.Insurance,
  [paths.mortgage]: ProductType.Mortgage,
  [paths.financialPlan]: ProductType.General,
  [paths.documents]: ProductType.General,
  [paths.profil]: ProductType.General,
  [paths.notifications]: ProductType.General,
}
const schema = yup.object().shape({
  productType: yup.string().required(i18n.t('errors.required_field')),
  question: yup.string().required(i18n.t('errors.required_field')),
})
interface IProps {
  open: boolean
  handleClose: () => void
}

export default function AskQuestion({ open, handleClose }: IProps) {
  const { t } = useTranslation()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const methods = useForm<QuestionEmailPost>({ resolver: yupResolver(schema) })

  React.useEffect(() => {
    setLoading(true)
    methods.setValue('productType', pathsListValue[location.pathname])
    setLoading(false)
  }, [location, methods])
  React.useEffect(() => {
    methods.setValue('question', '')
  }, [methods])
  const dispatch = useAppDispatch()

  const handleSumbitForm = (d: QuestionEmailPost) => {
    setLoading(true)

    const emailApi = new EmailApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: handleAccessToken.getAccessTokenSilently(),
      }),
    )

    emailApi
      .sendQuestionEmail('fr', d)
      .then(() => {
        dispatch(setSnackbar(true, 'success', t('ask_question.success')))
        setLoading(false)
        handleClose()
      })
      .catch((err: Error) => {
        dispatch(setSnackbar(true, 'error', (err as Error).message))
      })
      .finally(() => setLoading(false))
  }
  return (
    <BasicModal
      open={open}
      handleClose={handleClose}
      title={t('ask_question.title')}
      showActionSection
      handleAction={methods.handleSubmit((d) => handleSumbitForm(d))}
    >
      <ApiStateHandler loading={loading}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <FormProvider {...methods}>
              <Box component="form">
                <Grid
                  container
                  spacing={3}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item md={12} xs={12}>
                    <Controller
                      control={methods.control}
                      name="productType"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{ width: '280px' }}
                          select
                          label={t('request_an_appointement.productType')}
                          error={!!methods.formState.errors.productType}
                          helperText={
                            methods.formState.errors.productType
                              ? methods.formState.errors.productType?.message
                              : ''
                          }
                        >
                          {listProductType.map((productType) => (
                            <MenuItem value={productType.value} key={productType.value}>
                              {productType.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Controller
                      control={methods.control}
                      name="question"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          sx={{
                            width: { sm: '590px', xs: '280px' },
                            mr: { sm: 0, xs: 1 },
                            marginRight: '0 !important',
                          }}
                          multiline
                          rows={4}
                          label={t('request_an_appointement.additional_information')}
                          error={!!methods.formState.errors.question}
                          helperText={
                            methods.formState.errors.question
                              ? methods.formState.errors.question?.message
                              : ''
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </FormProvider>
          </Box>
        </Box>
      </ApiStateHandler>
    </BasicModal>
  )
}
