import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import AskQuestion from '../../../screens/askQuestion'
import JoinUs from '../../../screens/joinUs'
import BasicModal from '../../global/basicModal'
import IconCalendar from '../../icons/calendar'
import IconPhone from '../../icons/phone'
import IconQuestion from '../../icons/question'
import RequestAnAppointement from '../../requestAnAppointement'

interface Iprops {
  full?: boolean
}

export default function CardCTA({ full }: Iprops) {
  const { palette } = useTheme()
  const { t } = useTranslation()
  const [orientationDevider, setOrientationDevider] = useState<number>(0)
  const { width } = useWindowDimensions()
  const [openJoinUs, setJoinUs] = useState(false)
  const [openRequestAnAppointement, setRequestAnAppointement] = useState(false)
  const [openAskQuestion, setAskQuestion] = useState(false)

  React.useEffect(() => {
    setOrientationDevider(width)
  }, [width])
  const handleCloseJoinUs = () => {
    setJoinUs(false)
  }
  const handleOpenJoinUS = () => {
    setJoinUs(true)
  }

  const handlecloseRequestAnAppointement = () => {
    setRequestAnAppointement(false)
  }
  const handleOpenRequestAnAppointement = () => {
    setRequestAnAppointement(true)
  }

  const handleOpenAskQuestion = () => {
    setAskQuestion(true)
  }
  const handleCloseAskQuestion = () => {
    setAskQuestion(false)
  }
  return (
    <Box
      sx={{
        height: { sm: '145px', xs: '465px' },
        width: full ? '100%' : '98%',
        mt: 5,
        display: 'flex',
        flexDirection: { sm: 'row', xs: 'column' },
        boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
        borderRadius: '5px 5px 5px 5px',
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          width: { sm: '35%', xs: '100%' },
          backgroundColor: palette.primary.main,
          height: { sm: '100%', xs: '20%' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            justifyContent: { sm: 'start', xs: 'center' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: { sm: '24px', xs: '20px' },
              letterSpacing: { sm: '0.75px', xs: '0.63px' },
              lineHeight: { sm: '24px', xs: '29px' },
              fontWeight: 'bold',
              color: palette.text.primary,
              textAlign: 'center',
            }}
          >
            {t('dashboard_cta.need_help')}
          </Typography>
          <Typography
            sx={{
              fontSize: { sm: '24px', xs: '20px' },
              letterSpacing: { sm: '0.75px', xs: '0.63px' },
              lineHeight: { sm: '24px', xs: '29px' },
              color: palette.text.primary,
              textAlign: 'center',
            }}
          >
            {t('dashboard_cta.cta_content')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: { sm: '65%', xs: '100%' },
          backgroundColor: '#fff',
          height: { sm: '100%', xs: '80%' },
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          alignContent: 'center',
          flexDirection: { sm: 'row', xs: 'column' },
          pl: 5,
          pr: 5,
        }}
      >
        <IconButton
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          onClick={handleOpenAskQuestion}
        >
          <IconQuestion fill={palette.primary.main} width="40px" height="40px" />
          <Typography sx={{ mt: 1 }}>{t('dashboard_cta.questions')}</Typography>
        </IconButton>
        <Divider
          orientation={orientationDevider > 400 ? 'vertical' : 'horizontal'}
          sx={{ height: { sm: '60%', xs: '0' }, width: { sm: '0', xs: '70%' } }}
        />
        <IconButton
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          onClick={handleOpenRequestAnAppointement}
        >
          <IconCalendar fill={palette.primary.main} width="40px" height="40px" />
          <Typography sx={{ mt: 1 }}>{t('dashboard_cta.appointment')}</Typography>
        </IconButton>
        <Divider
          orientation={orientationDevider > 400 ? 'vertical' : 'horizontal'}
          sx={{ height: { sm: '60%', xs: '0' }, width: { sm: '0', xs: '70%' } }}
        />

        <IconButton
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          onClick={handleOpenJoinUS}
        >
          <IconPhone fill={palette.primary.main} width="40px" height="40px" />
          <Typography sx={{ mt: 1 }}>{t('dashboard_cta.join')}</Typography>
        </IconButton>
        <BasicModal
          open={openJoinUs}
          handleClose={handleCloseJoinUs}
          title={t('join_us.title')}
          showActionSection={false}
        >
          <JoinUs />
        </BasicModal>

        <RequestAnAppointement
          modal
          handlecloseRequestAnAppointement={handlecloseRequestAnAppointement}
          openRequestAnAppointement={openRequestAnAppointement}
        />
        <AskQuestion open={openAskQuestion} handleClose={handleCloseAskQuestion} />
      </Box>
    </Box>
  )
}
CardCTA.defaultProps = {
  full: false,
}
