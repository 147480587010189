/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, MenuItem, TextField, Typography, useTheme } from '@mui/material'
import {
  DesktopDatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enCA, frCA } from 'date-fns/locale'
import Holidays from 'date-holidays'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import setSnackbar from 'src/redux/actions/snackbar.action'
import { useAppDispatch } from 'src/redux/store'
import handleAccessToken from 'src/services/config'
import { AppointmentEmailPost, CalendarEventApi, Configuration, ProductType } from '../../api'
import { listProductType } from '../../data/data'
import { TimeSlotEn, TimeSlotFr } from '../../helpers/constants'
import i18n from '../../helpers/i18n'
import paths from '../../routes/paths'
import ApiStateHandler from '../apiStateHandler'
import ButtonRounded from '../global/buttonRounded'

interface IProps {
  loading: boolean
  methods: UseFormReturn<AppointmentEmailPost, any>
  handleAction: () => void
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  showActionButton: boolean
}
const pathsListValue = {
  [paths.accounting]: ProductType.Accounting,
  [paths.general]: ProductType.General,
  [paths.investement]: ProductType.Investment,
  [paths.insurance]: ProductType.Insurance,
  [paths.mortgage]: ProductType.Mortgage,
  [paths.financialPlan]: ProductType.General,
  [paths.documents]: ProductType.General,
  [paths.profil]: ProductType.General,
}

export default function RequestAnAppointementForm({
  loading,
  methods,
  handleAction,
  setLoading,
  showActionButton,
}: IProps) {
  const { t } = useTranslation()
  const locale = i18n.language === 'en' ? enCA : frCA
  const TimeSlotLocale = i18n.language === 'en' ? TimeSlotEn : TimeSlotFr
  const { palette } = useTheme()
  const location = useLocation()
  const [holidaysEvent, setHolidaysEvent] = useState<string[]>([])
  const dispatch = useAppDispatch()

  const color = palette.primary.main
  React.useEffect(() => {
    setLoading(true)
    methods.setValue('productType', pathsListValue[location.pathname])

    setLoading(false)
  }, [location, methods, setLoading])

  const disableHolidays = (
    date: number,
    selectedDates: Array<number | null>,
    pickersDayProps: PickersDayProps<number>,
  ) => {
    const hd = new Holidays('CA', 'QC')
    const tz = hd.getTimezones()[0]

    if (
      holidaysEvent.find((elem) => elem === new Date(moment.tz(date, tz).format()).toDateString())
    ) {
      return <PickersDay {...pickersDayProps} disabled />
    }
    return <PickersDay {...pickersDayProps} />
  }

  function disableWeekends(date: number) {
    return !!(new Date(date).getDay() === 0 || new Date(date).getDay() === 6)
  }

  const getHolidays = React.useCallback(() => {
    setLoading(true)

    const calendarEventApi = new CalendarEventApi(
      new Configuration({
        basePath: `${process.env.REACT_APP_BACKEND_API_URL}`,
        accessToken: handleAccessToken.getAccessTokenSilently(),
      }),
    )

    calendarEventApi
      .getFutureCalendarEvents('fr')
      .then((res) => {
        const hd = new Holidays('CA', 'QC')
        const tz = hd.getTimezones()[0]
        const dates = res.data.map((elem) =>
          new Date(moment.tz(elem.date, tz).format()).toDateString(),
        )
        setHolidaysEvent(dates)

        setLoading(false)
      })
      .catch((err: Error) => {
        dispatch(setSnackbar(true, 'error', err.message))
      })
      .finally(() => setLoading(false))
  }, [dispatch, setLoading])

  React.useEffect(() => {
    getHolidays()
  }, [getHolidays])

  React.useEffect(() => {
    if (locale && locale.options) {
      locale.options.weekStartsOn = 0
    }
  }, [locale])

  return (
    <ApiStateHandler loading={loading}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormProvider {...methods}>
            <Box component="form">
              <Grid
                container
                spacing={3}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item md={12} xs={12}>
                  <Controller
                    control={methods.control}
                    name="productType"
                    defaultValue="GENERAL"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ width: '280px', mt: 3 }}
                        select
                        label={t('request_an_appointement.productType')}
                        error={!!methods.formState.errors.productType}
                        helperText={
                          methods.formState.errors.productType
                            ? methods.formState.errors.productType?.message
                            : ''
                        }
                      >
                        {listProductType.map((productType) => (
                          <MenuItem value={productType.value} key={productType.value}>
                            {productType.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Typography>{t('request_an_appointement.sub_title')}</Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    display: { sm: 'block', xs: 'flex' },
                  }}
                >
                  <Controller
                    control={methods.control}
                    name="availableDate"
                    defaultValue={new Date().setDate(new Date().getDate() + 3)}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                        <DesktopDatePicker
                          {...field}
                          label={t('mortgage_screen.request_quote_tab.form.date_pick_number_one')}
                          inputFormat="dd/MM/yyyy"
                          disablePast
                          shouldDisableDate={(date) => disableWeekends(date)}
                          minDate={new Date().setDate(new Date().getDate() + 2)}
                          renderDay={(
                            day: number,
                            selectedDays: number[],
                            pickersDayProps: PickersDayProps<number>,
                          ) => disableHolidays(day, selectedDays, pickersDayProps)}
                          renderInput={(params) => (
                            <TextField
                              error={!!methods.formState.errors.availableDate}
                              sx={{
                                width: '280px',
                                mr: { sm: 1.5, xs: 0 },
                                mb: { sm: 0, xs: 1.5 },
                                svg: { color },
                              }}
                              helperText={
                                methods.formState.errors.availableDate
                                  ? methods.formState.errors.availableDate?.message
                                  : ''
                              }
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <Controller
                    control={methods.control}
                    name="availableTimeSlot"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        sx={{ width: '280px', ml: { sm: 1.5, xs: 0 }, mb: { sm: 0, xs: 1.5 } }}
                        {...field}
                        select
                        label={t('request_an_appointement.availableTimeSlot')}
                        error={!!methods.formState.errors.availableTimeSlot}
                        helperText={
                          methods.formState.errors.availableTimeSlot
                            ? methods.formState.errors.availableTimeSlot?.message
                            : ''
                        }
                      >
                        {Object.entries(TimeSlotLocale).map(([key, timeSlotValue]: any) => (
                          <MenuItem value={timeSlotValue} key={key}>
                            {timeSlotValue}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  display="flex"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    display: { sm: 'block', xs: 'flex' },
                  }}
                >
                  <Controller
                    control={methods.control}
                    name="availableDate2"
                    defaultValue={new Date().setDate(new Date().getDate() + 3)}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                        <DesktopDatePicker
                          {...field}
                          label={t('request_an_appointement.availableDate2')}
                          inputFormat="dd/MM/yyyy"
                          disablePast
                          shouldDisableDate={(date) => disableWeekends(date)}
                          minDate={new Date().setDate(new Date().getDate() + 2)}
                          renderDay={(
                            day: number,
                            selectedDays: number[],
                            pickersDayProps: PickersDayProps<number>,
                          ) => disableHolidays(day, selectedDays, pickersDayProps)}
                          renderInput={(params) => (
                            <TextField
                              sx={{
                                width: '280px',
                                mr: { sm: 1.5, xs: 0 },
                                mb: { sm: 0, xs: 1.5 },
                                svg: { color },
                              }}
                              error={!!methods.formState.errors.availableDate2}
                              helperText={
                                methods.formState.errors.availableDate2
                                  ? methods.formState.errors.availableDate2?.message
                                  : ''
                              }
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <Controller
                    control={methods.control}
                    name="availableTimeSlot2"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        sx={{ width: '280px', ml: { sm: 1.5, xs: 0 }, mb: { sm: 0, xs: 1.5 } }}
                        {...field}
                        select
                        label={t('request_an_appointement.availableTimeSlot2')}
                        error={!!methods.formState.errors.availableTimeSlot2}
                        helperText={
                          methods.formState.errors.availableTimeSlot2
                            ? methods.formState.errors.availableTimeSlot2?.message
                            : ''
                        }
                      >
                        {Object.entries(TimeSlotLocale).map(([key, timeSlotValue]: any) => (
                          <MenuItem value={timeSlotValue} key={key}>
                            {timeSlotValue}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Controller
                    control={methods.control}
                    name="additionalInformation"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{
                          width: { sm: '588px', xs: '280px' },
                          mr: { sm: 0, xs: 1 },
                          marginRight: '0 !important',
                        }}
                        multiline
                        rows={4}
                        label={t('request_an_appointement.additional_information')}
                        error={!!methods.formState.errors.additionalInformation}
                        helperText={
                          methods.formState.errors.additionalInformation
                            ? methods.formState.errors.additionalInformation?.message
                            : ''
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </FormProvider>
          {showActionButton && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 2,
              }}
            >
              <ButtonRounded
                handleAction={handleAction}
                display="flex"
                title={t('common.submit')}
                borderColor={palette.primary.main}
              />
            </Box>
          )}
        </Box>
      </Box>
    </ApiStateHandler>
  )
}
